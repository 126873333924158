.correctAnswer {
  color: var(--ion-color-success);
  font-size: var(--endgame-window-correct-answer-font-size);
}

.endGameWindow {
  min-height: 50vh;
}

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen and (min-device-width: 768px) {
  .correctAnswer {
    font-size: var(--font-size-xl);
  }
}