.deleteAccountContainer {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  height: 100%;
}

.deleteAccountConfirm {
  text-align: center;
}

.backBtn {
  padding: var(--navbar-button-padding) !important;
  height: 100% !important;
}

.deleteEndModal {
  text-align: center;
  max-height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: var(--ion-color-primary-contrast);
}

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen
  and (min-device-width: 768px) {
    .deleteAccountConfirm {
      font-size: var(--font-description-size-xl);
    }

    .backBtn {
      font-size: var(--navbar-font-size-xl) !important;
    }
}