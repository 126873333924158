.myModalRoot {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
}
.myModalBackground {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.26);
}

.myModalContainer {
  position: relative;
  background-color: var(--my-modal-background-color);
  border-radius: var(--my-modal-border-radius);
  border-width: var(--my-modal-border-width);
  border-color: var(--my-modal-border-color);
  border-style: solid;
  margin: var(--my-unit-4);
  padding: var(--my-unit-4);
  z-index: 5;
}

.myModalCloseButton {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 10px;
  margin-right: 10px;
  --padding-end: 0;
  --padding-start: 0;
  font-size: 20px;
}

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen
  and (min-device-width: 768px) {
    .myModalCloseButton {
      font-size: 28px;
    }
}