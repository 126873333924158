.windowWithStrokeWindow {
  width: 100%;
  height: 100%;
  /* min-height: 50vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.windowWithStrokeImage {
  width: 40%;
  margin-bottom: var(--my-unit-2);
  object-fit: contain;
}

.windowWithStrokeTitle {
  background-image: url('../../../public/assets/stroke.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 40px;
  width: 100%;
  font-size: var(--windowWithStroke-title-font-size);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--my-unit-4);
}

.windowWithStrokeText {
  color: var(--text-2-color);
  font-size: var(--windowWithStroke-description-font-size);
  text-align: center;
}

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen
  and (min-device-width: 768px) {
    .windowWithStrokeTitle {
      height: 65px;
      font-size: var(--font-size-xl);
    }

    .windowWithStrokeText {
      font-size: var(--font-description-size-xl);
    }
}