.myButton {
  --box-shadow: none;
  --border-color: var(--my-button-border-color);
  --border-width: var(--my-button-border-width);
  --border-style: solid;
  --border-radius: var(--my-button-border-radius);
}
/* .myButton::before {
  content: '';

} */
