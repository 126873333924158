.qrCodeScannerContainer {
  padding: var(--view-padding-vertical-no-header) var(--view-padding-horizontal);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.postScanContainer {
  padding: var(--view-padding-vertical-no-header) var(--view-padding-horizontal);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.scanExitBtn {
  visibility: visible;
  /* width: 100px;
  height: 100px; */
  position: fixed;
  right: 30px;
  top: 50px;
}

.upperDescription {
  font-size: var(--qr-code-scanner-description-font-size);
  text-align: center;
}

.scanButton {
  min-width: 200px;
  max-width: 100%;
  margin-top: var(--my-unit-3);
}

.unlockSuccessModal {
  text-align: center;
  max-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.unlockButton {
  position: absolute;
  display: block;
  bottom: 50px;
  left: 50%;
  margin-left: -62.5px;
  min-width: 150px;
}

.unlockSuccessModalImage {
  height: 100px;
}

.unlockSuccessModalButton {
  width: 150px;
}

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen
  and (min-device-width: 768px) {
    .upperDescription {
      font-size: var(--font-size-xl);
    }

    .scanButton {
      font-size: var(--button-font-size-xl);
    }

    .unlockButton {
      font-size: var(--button-font-size-xl);
    }
}