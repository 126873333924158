.inputLabel {
  width: 100% !important;
}

.inputLabelText {
  /* padding: 30px; */
  font-size: var(--label-font-size);
  color: var(--label-color);
}

.simpleInputField {
  border-bottom: var(--simple-input-field-border-width) solid black;
  --padding-start: var(--simple-input-field-padding);
  --padding-end: var(--simple-input-field-padding);
  --padding-bottom: var(--simple-input-field-padding);
  --padding-top: 0;
}

.simpleInputFieldError {
  color: var(--error-color);
}

.simpleInputFieldErrorSpacer {
  height: 20.5px;
}
