.upperImage {
  height: var(--game-image-height);
  /* width: var(--game-image-width); */
  margin-left: auto;
  margin-right: auto;
  display: block;
  object-fit: contain;
}

.riddleSubtitle {
  margin-bottom: 0;
}

.riddleDescription {
  margin-top: 0;
  width: 100%;
  min-height: 50px;
  overflow: auto;
  padding-left: var(--my-unit-2);
  padding-right: var(--my-unit-2);
}

.riddleImage {
  height: var(--game-image-height);
  /* width: var(--game-image-width); */
  margin-left: auto;
  margin-right: auto;
}

.description {
  width: 100%;
  text-align: left;
  color: var(--text-2-color);
  padding-left: var(--my-unit-3);
  padding-right: var(--my-unit-3);
}

.answerCtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.correctAnswer {
  background-color: var(--ion-color-success);
}
.wrongAnswer {
  background-color: var(--failure-color);
}

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen
  and (min-device-width: 768px) {

    .riddleImage {
      height: var(--game-image-height-xl);
    }

    .riddleSubtitle {
      font-size: var(--font-size-xl);
    }

    .riddleDescription {
      font-size: var(--font-description-size-xl);
    }

    .continueButton {
      font-size: var(--button-font-size-xl);
    }
}