.inputLabel {
  width: 100% !important;
}

/* .myInputField {
  margin-top: var(--my-unit-2);
} */

.inputLabelText {
  /* padding: 30px; */
  font-size: var(--label-font-size);
  color: var(--label-color);
}

.inputFieldContainer {
  width: 100%;
}

.inputField {
  background-color: var(--input-field-background-color);
  border-radius: var(--input-field-border-radius);
  margin: var(--my-unit) 0;
}

.inputFieldError {
  color: var(--error-color);
}

.inputFieldErrorSpacer {
  height: 20.5px;
}

.iconButton {
  position: absolute;
  z-index: 2;
  bottom: 32px;
  right: 14px;
}