.upperSectionContainer {
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bottomSectionContainer {
  min-height: 70%;
  background-color: var(--ion-color-secondary);
  padding: var(--my-unit-10) var(--my-unit-5) var(--my-unit-5);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.itinerariesContainer {
  transform: translateY(-110px);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.itineraryButton {
  --box-shadow: none;
  --border-color: var(--box-border-color);
  --border-width: var(--box-border-width);
  --border-style: solid;
  --border-radius: 23px;
  --padding-end: 0;
  --padding-start: 0;
  height: 100px;
  width: 100%;
}

.itineraryBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  font-size: 18px;
  text-transform: uppercase;
  white-space: normal;
}

.itineraryName {
  align-self: flex-start;
  width: 100%;
  max-height: 50px;
}

.itineraryCity {
  align-self: flex-end;
  color: var(--text-2-color);
}

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen
  and (min-device-width: 768px) {
    .itineraryTitle {
      font-size: var(--font-size-xl);
      padding: var(--my-unit-3);
    }

    .itineraryBox {
      font-size: var(--font-description-size-xl);
    }
}