.sliderContainer {
  height: 90%;
}

.sliderViewImg {
  height: 200px;
}

.discoverMoreButton {
  padding: var(--my-unit);
  min-height: 56px;
}

.viewContainer {
  padding: var(--view-padding-vertical) var(--view-padding-horizontal);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.chipsContainer {
  max-height: 10%;
  padding: 0 var(--chips-container-padding);
}

.selectionChip {
  padding: var(--chip-padding);
  border-radius: 100px;
  --color: var(--ion-color-tertiary-contrast);
  --background: var(--ion-color-tertiary);
}

.tagName {
  font-size: var(--itinerary-tag-selection-font-size);
}

.tagSlideImage {
  height: 200px !important;
}

.sliderBullet {
  background-color: var(--ion-color-primary) !important;
}
.sliderBulletActive {
  background-color: var(--ion-color-primary-contrast) !important;
}

.centeredContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen
  and (min-device-width: 768px) {
    .discoverMoreButton {
      font-size: var(--button-font-size-xl);
    }

    .tagName {
        font-size: var(--font-size-xl);
    }

    .selectionChip {
        font-size: var(--chip-font-size-xl);
        height: 50px;
    }
}