.paymentForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

}

.paymentElement {
  width: 100%;
  max-width: 600px;
}

.payButton {
  margin-top: var(--my-unit-3);
}

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen
  and (min-device-width: 768px) {
    .payButton {
      font-size: var(--button-font-size-xl);
    }
}