.signupFormContainer {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  height: 100%;
}

.signupForm {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  width: 50%;
  min-width: 260px;
}

.registerBtn {
  padding: var(--navbar-button-padding) !important;
  height: 100% !important;
}

/* .fieldError {
} */

.logo {
  max-width: 200px;
}

.invalidCredentials {
  color: var(--error-color);
}

.invalidCredentialsSpacer {
  height: 38px;
}

.inputItem {
  width: 100%;
  min-width: 260px;
}

.inputField {
  border: 2px solid white;
}
.inputFieldError {
  border: 2px solid red;
}

.recoverEndModal {
  text-align: center;
  max-height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: var(--ion-color-primary-contrast);
}

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen
  and (min-device-width: 768px) {
    .registerBtn {
      font-size: var(--navbar-font-size-xl) !important;
    }

    .enterBtn {
      font-size: var(--button-font-size-xl);
    }
    /* .nextBtn {
      text-align: right !important;
    }
    .backBtn {
      text-align: start !important;
    } */
}