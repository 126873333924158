.upperImage {
  height: var(--game-image-height);
  /* width: var(--game-image-width); */
  margin-left: auto;
  margin-right: auto;
  display: block;
  object-fit: contain;
}

.gameImage {
  width: 100%;
  height: var(--game-image-height);
  /* width: var(--game-image-width); */
  margin-left: auto;
  margin-right: auto;
}

.description {
  width: 100%;
  text-align: left;
  text-align: justify!important;
  line-height: 25px;
  color: var(--text-2-color);
  padding-left: var(--my-unit-3);
  padding-right: var(--my-unit-3);
  min-height: 50px;
  max-height: 180px;
  overflow: auto;
}

.descriptionAndroid {
  height: 190px;
}

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen
  and (min-device-width: 768px) {
    .upperText {
      font-size: var(--font-size-xl);
    }

    .description {
      font-size: var(--font-description-size-xl);
    }
}

.audioControl {
  width: 100%;
  text-align: center;
}

.audioButton {
  margin-top: 20px;
  font-size: 5rem;
  color: var(--ion-color-primary);
  cursor: pointer;
}

.container {
  position: relative;
  width: 100%;
}