.upperImage {
  height: var(--game-image-height);
  /* width: var(--game-image-width); */
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
}

.quizWindow {
  width: 100%;
  /* height: 100%; */
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.answersContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.confirmButton {
  align-self: flex-end;
}

.imageButtonContainer {
  width: 115px;
  width: 115px;
}

.imageButton {
  padding: 9.5px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.answerImg {
  height: 100%;
  object-fit: contain;
}

.correctImg {
  object-fit: contain;
}

.imageButtonText {
    color: var(--ion-color-primary-contrast);
    font-size: var(--drawings-and-words-answer-font-size);
    margin-bottom: var(--my-unit);
}

.clickedAnswer {
  border-style: solid;
  border-image: url("../../../../public/assets/games/riddle/answerFrame.svg");
  border-image-slice: 15%;
  border-image-outset: 0px;
  border-image-width: 15%;
  border-image-repeat: stretch;
  padding: 8px;
}

.questionText {
  padding-left: var(--my-unit-2);
  padding-right: var(--my-unit-2);
}

/* .correctAnswer {
  background-color: var(--ion-color-success);
}
.wrongAnswer {
  background-color: var(--failure-color);
} */
/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen
  and (min-device-width: 768px) {
    .questionText {
      font-size: var(--font-description-size-xl);
    }

    .imageButtonContainer {
      width: 140px;
      height: 140px;
    }

    .confirmButton {
      width: 80px;
      height: 80px;
    }

    .confirmImg {
      width: 100%;
      height: 100%;
    }
}