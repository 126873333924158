.signupFormContainer {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  /* height: 100%; */
}

.signupForm {
  width: 100%;
  min-width: 260px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.inputItem {
  width: 100%;
  margin-bottom: -20px!important;
}

input {
  padding:8px 10px!important;
}

.inputField {
  border: 2px solid white;
}

.inputFieldError {
  border: 2px solid red;
}

/* .fieldError {
} */

.logo {
  max-width: 200px;
}

.loginBtn {
  padding: var(--navbar-button-padding) !important;
  height: 100% !important;
}

.signupEndModal {
  text-align: center;
  max-height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: var(--ion-color-primary-contrast);
}

.customCheckbox {
  margin-right: 6px;
  --size: 25px;
  --background: white;
  --background-checked: white;
}

.customCheckboxError {
  --border-color: red;
}


/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen and (min-device-width: 768px) {
  .loginBtn {
    font-size: var(--navbar-font-size-xl) !important;
  }

  .registerBtn {
    font-size: var(--button-font-size-xl);
  }

  /* .nextBtn {
      text-align: right !important;
    }
    .backBtn {
      text-align: start !important;
    } */
}