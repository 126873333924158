@font-face {
  font-family: "Hero Light";
  src: url("../public/assets/fonts/hero2Cufonfonts/HeroLight-Regular.otf");
  font-weight: normal;
}

@font-face {
  font-family: "Hero Light";
  src: url("../public/assets/fonts/hero2Cufonfonts/HeroLight-Bold.otf");
  font-weight: bold;
}

@font-face {
  font-family: "Hero Light";
  src: url("../public/assets/fonts/hero2Cufonfonts/HeroLight-Light.otf");
  font-weight: 100;
}

@font-face {
  font-family: "Circular";
  src: url("../public/assets/fonts/circular/CircularStd Book.otf");
  font-weight: normal;
}

@font-face {
  font-family: "Circular";
  src: url("../public/assets/fonts/circular/CircularStd Medium.otf");
  font-weight: bold;
}

@font-face {
  font-family: "Roboto";
  src: url("../public/assets/fonts/roboto/Roboto-Medium.ttf");
  font-weight: bold;
}

@font-face {
  font-family: "SF Pro Text Regular";
  src: url("../public/assets/fonts/SF-Pro-Text-Regular/SF-Pro-Text-Regular.otf");
  font-weight: normal;
}

body.scanner-active {
  --background: transparent;
  --ion-background-color: transparent;
  /* visibility: hidden; */
}

/*
.scan-active, .scan-active *:not(.scan-exit-button):not(.scan-exit-button *) {
  visibility: hidden;
  pointer-events: none;
} */

*:not(.secondary-font) {
  font-family: "Hero Light";
  font-weight: bold;
}

.secondary-font {
  font-family: "Circular";
  font-weight: normal;
}

.swiper-pagination-bullet {
  background-color: white !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  background-color: black !important;
}

.backdrop-sensitive.backdrop-active {
  pointer-events: none;
  filter: brightness(0.74);
}

.backdrop-sensitive.semitransparent-active {
  pointer-events: none;
  opacity: 0.5;
}

.centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.zonzo-scrollbar-primary::-webkit-scrollbar,
.zonzo-scrollbar-secondary::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.zonzo-scrollbar-primary::-webkit-scrollbar-track {
  background: var(--scrollbar-primary-track-color);
}

/* Handle */
.zonzo-scrollbar-primary::-webkit-scrollbar-thumb {
  background: var(--scrollbar-primary-handle-color);
}

/* Handle on hover */
.zonzo-scrollbar-primary::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-primary-handle-hover-color);
}

/* Track */
.zonzo-scrollbar-secondary::-webkit-scrollbar-track {
  background: var(--scrollbar-secondary-track-color);
}

/* Handle */
.zonzo-scrollbar-secondary::-webkit-scrollbar-thumb {
  background: var(--scrollbar-secondary-handle-color);
}

/* Handle on hover */
.zonzo-scrollbar-secondary::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-secondary-handle-hover-color);
}

.qr-code-scanner {
  width: 100%;
  height: 50vh;
}

.buttonHover:hover {
  cursor: pointer !important;
  background-color: #eee !important;
}

.button-full-width {
  width: 80%;
}

.button-left {
  position: absolute!important;
  bottom: 15px;
  left: 15px;
  width: 90px;
  float: left;
}

.button-right {
  position: absolute!important;
  float:right;
  bottom: 15px;
  width: 90px;
  right: 15px !important;
}
.zonzo-scrollbar-secondary {
  text-align: justify!important;
}