.viewContainer {
  padding: var(--view-padding-vertical) var(--view-padding-horizontal);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.walletTitle {
  text-align: center;
}

.walletImg {
  height: 100px;
  object-fit: contain;
}

.balanceText {
  font-size: var(--wallet-balance-font-size);
  margin-top: var(--my-unit-6);
}

.convertedText {
  font-size: var(--wallet-converted-font-size);
  color: var(--text-2-color);
}

.sendBtn {
  position: absolute;
  bottom: 30px;
}

.checkPermissionsText {
  position: absolute;
  bottom: 30px;
  padding: 0 var(--my-unit-2);
  text-align: center;
}

.transferModal {
  text-align: center;
  max-height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: var(--ion-color-primary-contrast);
}

.transferModalFooter {
  margin-top: var(--my-unit-2);
}

.transferModalBtn {
  width: 40%;
}

.transferModalBtn:first-child {
  margin-right: var(--my-unit);
}

.nftNavbarBtn {
  padding: var(--my-unit) !important;
  font-size: 14px !important;
}

.walletAddress {
  font-size: 12px;
  position: absolute;
  color: var(--text-2-color);
  left: 50%;
  transform: translateX(-50%);
  bottom: -2px;
  display: flex;
  text-transform: initial;
}

.copiedAlert {
  font-size: 13px;
  padding: 3px;
  position: absolute;
  bottom: 0;
  color: black;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: .23s ease-in-out;
}

.copiedAlertVisible {
  bottom: -30px;
  opacity: 1;
}

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen and (min-device-width: 768px) {
  .walletTitle {
    font-size: var(--font-size-xl);
  }

  .recapText {
    font-size: var(--font-description-size-xl);
  }

  .walletBtn {
    font-size: var(--button-font-size-xl);
  }

  .nftNavbarBtn {
    font-size: var(--navbar-font-size-xl) !important;
  }
}