.sliderContainer {
  height: 100%;
  justify-content: start;
}

.sliderViewImg {
  height: 200px;
}

.beginButton {
  width: 150px;
}

.navButtonCtnLeft, .navButtonCtnRight {
  width: 30%;
  min-height: 50px;
}
.navButtonCtnLeft {
  justify-content: left;
}
.navButtonCtnRight {
  justify-content: right;
}

.nextBtn, .backBtn {
  /* width: 100%; */
  padding: var(--navbar-button-padding) !important;
  height: 100% !important;
}
.nextBtn {
  justify-self: end;
}
.backBtn {
  justify-self: start;
}
/*
.nextSpan, .backSpan {
  width: 100%;
} */

.viewContainer {
  height: 100%;
  padding: 0 var(--view-padding-vertical) var(--view-padding-horizontal);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.viewDescription {
  font-size: var(--tutorial-font-size);
}

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen
  and (min-device-width: 768px) {
    .nextBtn, .backBtn {
      font-size: var(--navbar-font-size-xl) !important;
    }

    .viewDescription {
      font-size: var(--font-description-size-xl);
    }

    .beginButton {
      font-size: var(--button-font-size-xl);
    }
    /* .nextBtn {
      text-align: right !important;
    }
    .backBtn {
      text-align: start !important;
    } */
}

/* @media (max-width: 300px) {
  .viewDescription {
    font-size: calc(var(--tutorial-font-size) * 0.7);
  }

  .sliderViewImg {
    height: 120px;
  }
} */
