/* .citySelectionIonContent {
  --keyboard-offset: 0px !important;
} */

.discoverButton {
  position: absolute;
  display: block;
  bottom: 50px;
  left: 50%;
  margin-left: -62.5px;
}

.citySelectionContainer {
  padding: var(--view-padding-vertical) var(--view-padding-horizontal);
  text-align: center;
  width: 100%;
  height: 100%;
}

.citySelectionForm {
  width: 100%;
  min-width: 260px;
}

.citySelectionDescription {
  font-size: var(--choose-itinerary-font-size);
  text-align: center;
  padding:0 20px;
}

.citySelectionAutocomplete {
  z-index: 1;
}
/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen
  and (min-device-width: 768px) {

    .citySelectionDescription {
      font-size: var(--font-size-xl);
    }

    .discoverButton {
      font-size: var(--button-font-size-xl);
    }
}