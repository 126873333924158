.navbarBackBtn, .navbarNextBtn {
  padding: var(--navbar-button-padding) !important;
}

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen
  and (min-device-width: 768px) {
    .navbarBackBtn, .navbarNextBtn {
      font-size: var(--navbar-font-size-xl) !important;
    }
}