.waypointAnecdoteContainer {
  padding: var(--view-padding-vertical) var(--view-padding-horizontal);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.tagImage {
  height: var(--tag-icon-height-small);
  object-fit: contain;
}

.characterImage {
  height: 25vh;
  border-radius: 200px;
  border: 4px solid #000;
}

.upperDescription {
  font-size: var(--waypoint-intro-title-font-size);
}

.balloonImage {
  height: var(--anecdote-image-height);
}

.anecdoteText {
  color: var(--text-2-color);
  text-align: justify !important;
  line-height: 25px;
  overflow: auto;
  width: 100%;
  min-height: 50px;
}

.anecdoteTextAndroid {
  height: 190px;
}

.readMoreButton {
  --color: var(--text-2-color);
  --padding-start: 0;
  align-self: flex-start;
  padding: 0;
  width: 60%;
}

.readMoreModal {
  text-align: center;
  max-height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.readMoreModal ion-button {
  width: 50px !important;
}

.continueButton {
  min-width: 200px;
  min-height: 56px;
  margin-top: var(--my-unit-2);
  /* margin-top: var(--my-unit-3); */
}

.audioControl {
  width: 50px;
  text-align: right;
  align-self: flex-end;
  position: absolute;
  right: 0;
  top: -5px;
}

.audioButton {
  font-size: 3.5rem;
  color: var(--ion-color-primary);
  cursor: pointer;
}

.moreContainer {
  width: 100%;
  position: relative;
}

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen and (min-device-width: 768px) {
  .anecdoteText,
  .readMoreParagraph {
    font-size: var(--font-description-size-xl);
  }

  .readMoreButton {
    font-size: var(--button-font-size-xl);
  }

  .continueButton {
    font-size: var(--button-font-size-xl);
  }
}
