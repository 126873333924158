.myWindowContainer {
  position: relative;
  background-color: var(--my-modal-background-color);
  border-radius: var(--my-modal-border-radius);
  border-width: var(--my-modal-border-width);
  border-color: var(--my-modal-border-color);
  border-style: solid;
  margin: var(--my-window-margin);
  padding: var(--my-window-padding);
  z-index: 3;
}
