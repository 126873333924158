/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #fada16;
  --ion-color-primary-rgb: 250, 218, 22;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #e0c514;
  --ion-color-primary-tint: #fadf32;

  /** secondary **/
  --ion-color-secondary: #fefdf0;
  --ion-color-secondary-rgb: 254, 253, 240;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #fffab3;
  --ion-color-secondary-tint: #c2c1b4;

  /** tertiary **/
  --ion-color-tertiary: #000000;
  --ion-color-tertiary-rgb: 0, 0, 0;
  --ion-color-tertiary-contrast: #fefdf0;
  --ion-color-tertiary-contrast-rgb: 254, 253, 240;
  --ion-color-tertiary-shade: #000000;
  --ion-color-tertiary-tint: #1a1a1a;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #666666;
  --ion-color-medium-rgb: 102, 102, 102;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #4b4a4a;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #e6e6e6;
  --ion-color-light-rgb: 230, 230, 230;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #969696;
  --ion-color-light-tint: #f0f0f0;

  --ion-background-color: #fada16;
  --ion-background-color-rgb: 250, 218, 22;

  /** colors **/
  --failure-color: var(--ion-color-danger);
  --text-2-color: var(--ion-color-medium);
  --label-color: var(--ion-color-medium);
  --error-color: var(--ion-color-danger);
  --input-field-background-color: var(--ion-color-medium-contrast);
  --my-button-border-color: var(--ion-color-secondary-contrast);
  --box-border-color: var(--my-button-border-color);
  --box-background-color: var(--ion-color-secondary);
  --my-modal-border-color: var(--my-button-border-color);
  --my-modal-background-color: var(--ion-color-secondary);
  --multiple-choice-quiz-answer-background-color: var(--ion-color-light);
  --scrollbar-primary-track-color: var(--ion-color-secondary-shade);
  --scrollbar-primary-handle-color: var(--ion-color-primary);
  --scrollbar-primary-handle-hover-color: var(--ion-color-primary-shade);
  --scrollbar-secondary-track-color: var(--ion-color-medium-contrast);
  --scrollbar-secondary-handle-color: var(--ion-color-medium-tint);
  --scrollbar-secondary-handle-hover-color: var(--ion-color-medium);

  /** units **/
  --my-unit: 8px;
  --my-unit-2: calc(var(--my-unit) * 2);
  --my-unit-3: calc(var(--my-unit) * 3);
  --my-unit-4: calc(var(--my-unit) * 4);
  --my-unit-5: calc(var(--my-unit) * 5);
  --my-unit-6: calc(var(--my-unit) * 6);
  --my-unit-7: calc(var(--my-unit) * 7);
  --my-unit-8: calc(var(--my-unit) * 8);
  --my-unit-9: calc(var(--my-unit) * 9);
  --my-unit-10: calc(var(--my-unit) * 10);

  /** borders **/
  --input-field-border-radius: 64px;
  --my-button-border-radius: 64px;
  --my-button-border-width: 3px;
  --box-border-radius: var(--my-button-border-radius);
  --box-border-width: var(--my-button-border-width);
  --my-modal-border-radius: 31px;
  --my-modal-border-width: var(--my-button-border-width);
  --simple-input-field-border-width: 2px;
  --reorder-letters-input-field-border-width: 2px;

  /** paddings **/
  --page-padding: var(--my-unit-4);
  --view-padding-vertical: var(--my-unit-2);
  --view-padding-horizontal: var(--my-unit-4);
  --view-padding-vertical-no-header: var(--my-unit-6);
  --view-padding-horizontal: var(--my-unit-4);
  --navbar-button-padding: var(--my-unit-3);
  --chips-container-padding: var(--my-unit-4);
  --chip-padding: var(--my-unit-2);
  --my-window-padding: var(--my-unit-2);
  --simple-input-field-padding: var(--my-unit);
  --reorder-letters-input-field-padding: var(--my-unit);

  /** margins **/
  --my-window-margin: var(--my-unit-2);

  /** widths **/
  /* --game-image-width: 130px; */

  /** heights **/
  --tag-icon-height-small: 140px;
  --game-image-height: 80px;
  --game-image-height-xl: 160px;
  --anecdote-image-height: 80px;
  --itinerary-complete-image-height: 120px;
  --intro-image-height: 120px;
  --rebus-image-height: 250px;
  --photoQuiz-image-height: 250px;

  /** sizes **/
  --font-size-xl: 20px;
  --font-description-size-xl: 23px;
  --chip-font-size-xl: 23px;
  --label-font-size: 14px;
  --tutorial-font-size: 23px;
  --menu-font-size: 16px;
  --menu-font-size-xl: 23px;
  --menu-title-font-size: 18px;
  --menu-title-font-size-xl: 25px;
  --navbar-font-size-xl: 23px;
  --button-font-size-xl: 23px;
  --choose-itinerary-font-size: 30px;
  --intro-font-size: 36px;
  --tag-selection-tag-name-font-size: 23px;
  --itinerary-selection-title-font-size: 20px;
  --qr-code-scanner-description-font-size: 20px;
  --waypoint-intro-title-font-size: 20px;
  --riddle-upper-text-font-size: 20px;
  --game-time-counter-font-size: 20px;
  --multiple-choice-quiz-answer-font-size: 14px;
  --drawings-and-words-answer-font-size: 14px;
  --windowWithStroke-title-font-size: 20px;
  --windowWithStroke-description-font-size: 14px;
  --wallet-balance-font-size: 30px;
  --wallet-converted-font-size: 18;
  --help-icon-size: 30px;
  --help-icon-size-xl: 50px;
  --endgame-window-correct-answer-font-size: 18px;
}