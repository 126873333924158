.viewContainer {
  padding: var(--view-padding-vertical) var(--view-padding-horizontal);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.tagImage {
  height: var(--tag-icon-height-small);
}

.upperDescription {
  font-size: var(--itinerary-selection-title-font-size);
}

.itinerariesListItem {
  margin-top: var(--my-unit-2);
  width: 100%;
}
.itinerariesListItemButton {
  margin-left: auto;
  margin-right: auto;
  height: 45px;
  width: 100%;
  white-space: break-spaces;
  /* word-break: break-all; */
}

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen
  and (min-device-width: 768px) {
    .itinerariesListItemButton {
      font-size: var(--button-font-size-xl);
    }

    .upperDescription {
        font-size: var(--font-size-xl);
    }
}