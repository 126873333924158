.chooseItineraryContainer {
  padding: var(--view-padding-vertical) var(--view-padding-horizontal);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.chooseItineraryDescription {
  font-size: var(--choose-itinerary-font-size);
  text-align: center;
}

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen
  and (min-device-width: 768px) {
    .chooseItineraryDescription {
        font-size: var(--font-size-xl);
    }
    .discoverButton {
      font-size: var(--button-font-size-xl);
    }
}