.menuButton {
  width: 100%;
  font-size: var(--menu-font-size);
}
.menuTitle {
  font-size: var(--menu-title-font-size);
}

.menuButtonIcon {
  margin-left: var(--my-unit-2);
}

.buttonsCtn {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen
  and (min-device-width: 768px) {
    .menuButton {
      font-size: var(--menu-font-size-xl);
    }
    .menuTitle {
      font-size: var(--menu-title-font-size-xl);
    }
}

.version {
  font-size: 13px;
  text-align: center;
  color: #000;
  margin-bottom: var(--my-unit-2);
}