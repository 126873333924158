.answerButton {
  --box-shadow: none;
  --padding-top: 2px;
  --padding-bottom: 2px;
  --padding-start: 2px;
  --padding-end: 2px;
  width: 80px;
  height: 80px;
  position: relative;
}
.answerButtonContent {
  width: 100%;
  height: 100%;
  border-style: solid;
  border-image: url("../../../../public/assets/games/riddle/answerFrame.svg");
  border-image-slice: 15%;
  border-image-outset: 0px;
  border-image-width: 15%;
  border-image-repeat: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  -ms-word-break: break-all;
  word-break: break-all;
  white-space: break-spaces;
}

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen
  and (min-device-width: 768px) {
    .answerButton {
      font-size: var(--font-description-size-xl);
      width: 130px;
      height: 130px;
    }
}