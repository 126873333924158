/* .tabBar {
    height: 6vh;
} */

.tabButtonContent {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: var(--my-unit); */
}

.tabButtonIcon {
    width: 100%;
    height: 60%;
}

.confirmLeaveModal {
    text-align: center;
    max-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    color: var(--ion-color-secondary-contrast);
}

.confirmLeaveModalButton {
    width: 100px;
}
.confirmLeaveModalButton:nth-child(1) {
    margin-right: var(--my-unit-2);
}

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen
  and (max-device-width: 400px) {
    .confirmLeaveModalButton:nth-child(1) {
        margin-right: 0;
    }
}