.itineraryRecapContainer {
  padding: var(--view-padding-vertical) var(--view-padding-horizontal);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.tagImage {
  height: var(--tag-icon-height-small);
}

.upperDescription {
  font-size: var(--waypoint-intro-title-font-size);
}

.recapText {
  color: var(--text-2-color);
  text-align: justify!important;
  line-height: 25px;
  width: 100%;
  overflow: auto;
  min-height: 50px;
  padding-left: var(--my-unit-2);
  padding-right: var(--my-unit-2);
}

.continueButton {
  min-width: 200px;
  min-height: 56px;
  /* margin-top: var(--my-unit-3); */
}

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen
  and (min-device-width: 768px) {
    .upperDescription {
      font-size: var(--font-size-xl);
    }

    .recapText {
      font-size: var(--font-description-size-xl);
    }

    .continueButton {
      font-size: var(--button-font-size-xl);
    }
}