.waypointIntroContainer {
  padding: var(--view-padding-vertical) var(--view-padding-horizontal);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.tagImage {
  height: var(--tag-icon-height-small);
  margin: auto;
  display: block;
  object-fit: contain;
}

.mapImage {
  height: 70px;
  margin:20px 0;
}

.upperDescription {
  font-size: var(--waypoint-intro-title-font-size);
}

.waypointIndication {
  width: 100%;
  max-height: 200px;
  min-height: 50px;
  text-align: justify!important;
  line-height: 25px;
  overflow: auto;
  padding-left: var(--my-unit-2);
  padding-right: var(--my-unit-2);
}

.waypointIndicationAndroid {
  height: 190px;
}

.startButton {
  min-height: 56px;
  /* margin-top: var(--my-unit-3); */
}

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen
  and (min-device-width: 768px) {
    .upperDescription {
      font-size: var(--font-size-xl);
    }

    .waypointIndication {
      font-size: var(--font-description-size-xl);
    }

    .startButton {
      font-size: var(--button-font-size-xl);
    }
}