.itineraryCompleteContainer {
  padding: var(--view-padding-vertical) var(--view-padding-horizontal);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.itineraryCompleteContainerMessage {
  padding: var(--view-padding-vertical) var(--view-padding-horizontal);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.feedbackWindow {
  width: 100%;
  /* height: 100%; */
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.upperImage {
  height: var(--itinerary-complete-image-height);
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
}

.walletBtn {
  min-height: 56px;
  width: 200px;
}

.marginTop {
  margin-top: var(--my-unit-4);
}
/*
.itineraryCompleteWindow {
  height: 30vh;
} */

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen
  and (min-device-width: 768px) {
    .walletBtn {
      font-size: var(--button-font-size-xl);
    }
}