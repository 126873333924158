.waypointGameContainer {
  padding: var(--view-padding-vertical) var(--view-padding-horizontal);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.fallbackContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.continueButton {
  min-width: 200px;
  min-height: 56px;
  margin-top: var(--my-unit-2);
  /* margin-top: var(--my-unit-3); */
}

.hintModal {
  text-align: center;
  display: flex;
  max-height: 50vh;
  width: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.hintParagraph {
  overflow: auto;
  padding-left: var(--my-unit-2);
  padding-right: var(--my-unit-2);
}

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen
  and (min-device-width: 768px) {
    .continueButton {
      font-size: var(--button-font-size-xl);
    }

    .hintBtn {
      font-size: var(--navbar-font-size-xl) !important;
    }
    .hintModal {
      font-size: var(--font-description-size-xl) !important;
    }

    .helpIcon {
      font-size: var(--help-icon-size-xl);
    }
}