.upperImage {
  height: var(--game-image-height);
  /* width: var(--game-image-width); */
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
}

.quizWindow {
  width: 100%;
  /* height: 100%; */
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.inputField {
  margin-top: var(--my-unit-8);
}

.confirmButton {
  align-self: flex-end;
}

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen
  and (min-device-width: 768px) {
    .questionText {
      font-size: var(--font-description-size-xl);
    }

    .inputField {
      font-size: var(--font-description-size-xl);
    }

    .confirmButton {
      width: 80px;
      height: 80px;
    }

    .confirmImg {
      width: 100%;
      height: 100%;
    }
}