.viewContainer {
  padding: var(--view-padding-vertical) var(--view-padding-horizontal);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.upperSectionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50%;
  justify-content: space-evenly;
}

.bottomSectionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50%;
  width: 100%;
  justify-content: end;
}

.tagImage {
  height: var(--tag-icon-height-small);
  object-fit: contain;
}

.upperDescription {
  font-size: var(--itinerary-selection-title-font-size);
}
.loginToContinue {
  font-size: var(--itinerary-selection-title-font-size);
  text-align: center;
}

.itineraryTitle {
  white-space: break-spaces;
  padding: var(--my-unit-3);
  /* word-break: break-all; */
  overflow: hidden;
  width: 100%;
}

.itineraryDescription {
  width: 100%;
  text-align: left;
  color: var(--text-2-color);
  padding-left: var(--my-unit-3);
  padding-right: var(--my-unit-3);
  height: 180px;
  overflow: auto;
}

.itineraryDescriptionAndroid {
  height: 190px;
}

.purchaseButton {
  margin-top: var(--my-unit-3);
}

.unlockSuccessModal {
  text-align: center;
  max-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.unlockSuccessModalImage {
  height: 100px;
}

.unlockSuccessModalButton {
  width: 150px;
}

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen
  and (min-device-width: 768px) {
    .upperDescription, .itineraryTitle, .loginToContinue {
        font-size: var(--font-size-xl);
    }

    .itineraryTitle {
      padding: var(--my-unit-4);
    }
    .itineraryDescription {
        font-size: var(--font-description-size-xl);
    }

    .purchaseButton {
      font-size: var(--button-font-size-xl);
    }
}