.upperSectionContainer {
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.costText {
  margin-top: var(--my-unit-3);
}

.bottomSectionContainer {
  height: 70%;
  background-color: var(--ion-color-secondary);
  padding: var(--my-unit-10) var(--my-unit-5) var(--my-unit-5);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.successModal {
  text-align: center;
  max-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.successModalImage {
  height: 100px;
}

.successModalButton {
  width: 150px;
}

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen
  and (min-device-width: 768px) {
    .itineraryTitle {
        font-size: var(--font-size-xl);
    }

    .itineraryTitle {
      padding: var(--my-unit-4);
    }
    .costText {
      font-size: var(--font-description-size-xl);
    }

    .purchaseButton {
      font-size: var(--button-font-size-xl);
    }
}