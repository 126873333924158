.upperImage {
  height: var(--game-image-height);
  /* width: var(--game-image-width); */
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
}

.quizWindow {
  width: 100%;
  /* height: 100%; */
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.confirmButton {
  align-self: flex-end;
  width: 60px;
  height: 60px;
}
.resetButton {
  width: 60px;
  height: 60px;
}

.clickedAnswer {
  background-color: var(--multiple-choice-quiz-answer-background-color);
}

.reorderLettersInputFieldContainer {
  width: 80%;
}

.reorderLettersInputField {
  text-align: center;
  height: 20px;
  margin-bottom: var(--my-unit);
}

.reorderLettersInputFieldBorder {
  border-bottom: var(--reorder-letters-input-field-border-width) solid black;
}

.characterButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.answerItem {
  width: 100%;
}
.answerItemContent {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-2-color);
  font-size: var(--multiple-choice-quiz-answer-font-size);
}

.reorderLettersWindowBottomSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.confirmImg {
  min-width: 15px;
}
.resetImg {
  min-width: 15px;
}

/* .correctAnswer {
  background-color: var(--ion-color-success);
}
.wrongAnswer {
  background-color: var(--failure-color);
} */

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen
  and (min-device-width: 768px) {
    .questionText {
      font-size: var(--font-description-size-xl);
    }

    .confirmButton, .resetButton {
      width: 80px;
      height: 80px;
    }

    .confirmImg, .resetImg {
      width: 100%;
      height: 100%;
    }
}