.answerButton {
  --box-shadow: none;
  --padding-top: 2px;
  --padding-bottom: 2px;
  --padding-start: 2px;
  --padding-end: 2px;
  width: 100%;
  position: relative;
  height: 45px;
}
.answerButtonContent {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-2-color);
  font-size: var(--multiple-choice-quiz-answer-font-size);
  white-space: initial;
}

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen
  and (min-device-width: 768px) {
    .answerButton {
      height: 65px;
    }

    .answerButtonContent {
      font-size: var(--font-description-size-xl);
    }
}