.viewContainer {
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.chooseButton {
  margin: 0 0 var(--my-unit-2);
  min-height: 56px;
  padding: var(--my-unit);
}

.tagImage {
  height: var(--tag-icon-height-small);
  margin: var(--my-unit-2) 0 0;
}

.chipsContainer {
  max-height: 10%;
  padding: 0 var(--chips-container-padding);
}

.selectionChip {
  padding: var(--chip-padding);
  border-radius: 100px;
  --color: var(--ion-color-tertiary-contrast);
  --background: var(--ion-color-tertiary);
}

.tagName {
  font-size: var(--tag-selection-tag-name-font-size);
  /* padding: var(--view-padding-vertical) var(--view-padding-horizontal); */
}

.itineraryImage {
  margin-left: auto;
  width: 50%;
}

.tagDescription {
  padding: 0 var(--my-unit-3);
  color: var(--text-2-color);
  height: 180px;
  overflow: auto;
  min-height: 50px;
  width: 90%;
}

.tagDescriptionAndroid {
  height: 190px;
}

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen
  and (min-device-width: 768px) {
    .chooseButton {
      font-size: var(--button-font-size-xl);
    }

    .tagName {
        font-size: var(--font-size-xl);
    }

    .tagDescription {
      font-size: var(--font-description-size-xl);
    }

    .selectionChip {
        font-size: var(--chip-font-size-xl);
        height: 50px;
    }
}