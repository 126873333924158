.upperImage {
  height: var(--game-image-height);
  /* width: var(--game-image-width); */
  margin-left: auto;
  margin-right: auto;
}

.quizWindow {
  width: 100%;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.confirmButton {
  align-self: flex-end;
}

.clickedAnswer {
  background-color: var(--multiple-choice-quiz-answer-background-color);
}

.answerCharactersContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.inputField {
  text-align: center;
  text-transform: uppercase;
}

.answerCharacter {
  width: 40px;
  height: 40px;
  border-style: solid;
  border-image: url("../../../../public/assets/games/riddle/answerFrame.svg");
  border-image-slice: 15%;
  border-image-outset: 0px;
  border-image-width: 15%;
  border-image-repeat: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
}
.answerCharacter:not(:last-child) {
  margin-right: var(--my-unit);
}

.hangmanImage {
  height: 100px;
}

.hangmanWindowUpperSection {
  width: 100%;
}

.hangmanWindowBottomSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.hangmanWindowLivesCounter {
  margin-left: 20px;
  font-size: var(--game-time-counter-font-size);
}

/* .correctAnswer {
  background-color: var(--ion-color-success);
}
.wrongAnswer {
  background-color: var(--failure-color);
} */

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen
  and (min-device-width: 768px) {
    .questionText {
      font-size: var(--font-description-size-xl);
    }

    .answerCharacter {
      width: 50px;
      height: 50px;
    }

    .answerCharactersContainer {
      margin-top: var(--my-unit-4);
      margin-bottom: var(--my-unit-4);
    }

    .inputField {
      margin: 0 var(--my-unit-4);
      font-size: var(--font-description-size-xl);
    }

    .hangmanWindowLivesCounter {
      font-size: var(--font-size-xl);
    }

    .confirmButton {
      width: 80px;
      height: 80px;
    }

    .confirmImg {
      width: 100%;
      height: 100%;
    }
}