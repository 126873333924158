.viewContainer {
  padding: var(--view-padding-vertical) var(--view-padding-horizontal);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.itineraryCard {
  border: 2px solid #000;
  border-radius: 20px;
  padding: var(--my-unit-2);
  margin-bottom: var(--my-unit-2);
  background-color: #fff;
}
.itineraryCard h1 {
  margin-top: -18px;
  font-size: 20px;
  font-weight: 600;
}
.itineraryCard ion-img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  float: left;
  margin-left: -4px;
}
.itineraryCard p {
  text-align: justify;
  line-height: 1.5;
  margin-top: 0;
}
.tagImage {
  height: var(--tag-icon-height-small);
}
.imgCategory {
  width: 50px !important;
  height: 50px !important;
  object-fit: cover;
}

.upperDescription {
  font-size: var(--itinerary-selection-title-font-size);
}

.itinerariesListItem {
  margin-top: var(--my-unit-2);
  width: 100%;
}
.itinerariesListItemButton {
  margin-left: auto;
  margin-right: auto;
  height: 45px;
  width: 100%;
  white-space: break-spaces;
  /* word-break: break-all; */
}

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen and (min-device-width: 768px) {
  .itinerariesListItemButton {
    font-size: var(--button-font-size-xl);
  }

  .upperDescription {
    font-size: var(--font-size-xl);
  }
}
