/* .answerButton {
  --box-shadow: none;
  --padding-top: 2px;
  --padding-bottom: 2px;
  --padding-start: 2px;
  --padding-end: 2px;
  position: relative;
  height: 100px;
  width: 100px;
} */

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen
  and (min-device-width: 768px) {
    .characterButton {
      width: 80px;
      height: 80px;
      font-size: var(--font-description-size-xl);
    }
}