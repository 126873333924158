.box {
  background-color: var(--box-background-color);
  border-color: var(--box-border-color);
  border-width: var(--box-border-width);
  border-radius: var(--box-border-radius);
  border-style: solid;
  padding: 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
