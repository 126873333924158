.languageSelectionContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: var(--page-padding);
}

.languageButtonsDivider {
  margin: 0 var(--my-unit);
}

.itButton {
  --background: #fb83f6;
  --background-activated: #fb83f6;
  width: 60px;
  height: 30px;
}

.enButton {
  --background: #6ccaff;
  --background-activated: #6ccaff;
  width: 60px;
  height: 30px;
}

.logo {
  /* max-width: 300px; */
  max-width: 200px;
  margin-bottom: var(--my-unit);
}
