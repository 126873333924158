.leaderboardIonContent {
  --keyboard-offset: 0px !important;
}

.upperSectionContainer {
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bottomSectionContainer {
  height: 70%;
  background-color: var(--ion-color-secondary);
  padding: 0 var(--my-unit-5) 0;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.itineraryAutocomplete {
  width: 70%;
}

.itinerariesContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.itineraryButton {
  box-shadow: none;
  border-color: var(--box-border-color);
  border-width: var(--box-border-width);
  border-style: solid;
  border-radius: 23px;
  padding: 0;
  margin:  var(--my-unit-2) auto;
  height: 120px;
  width: 90%;
  overflow: hidden;
}

.itineraryBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px;
  font-size: 18px;
}

.userIndicator, .scoreIndicator {
  margin-left: auto;
  margin-right: auto;
}

.scoreIndicator {
  color: var(--text-2-color);
}

.scoreItinerary {
  color: var(--text-2-color);
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}

.noScores, .errorText {
  margin-top: var(--my-unit-5);
}

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen
  and (min-device-width: 768px) {
    .upperSectionContainer {
      font-size: var(--font-size-xl);
    }

    .leaderboardTitle {
      padding: var(--my-unit-3);
    }

    .itineraryBox {
      font-size: var(--font-description-size-xl);
    }

    .errorText {
      font-size: var(--font-description-size-xl);
    }
}