.upperImage {
  height: var(--game-image-height);
  /* width: var(--game-image-width); */
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
}

.quizWindow {
  width: 100%;
  /* height: 100%; */
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.answersCtn {
  width: 100%;
}

.confirmButton {
  align-self: flex-end;
  width: 60px;
  height: 60px;
}

.clickedAnswer {
  background-color: var(--multiple-choice-quiz-answer-background-color);
}

/* .correctAnswer {
  background-color: var(--ion-color-success);
}
.wrongAnswer {
  background-color: var(--failure-color);
} */

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen
  and (min-device-width: 768px) {
    .questionText {
      font-size: var(--font-description-size-xl);
    }

    .confirmButton {
      width: 80px;
      height: 80px;
    }

    .tickImg {
      width: 100%;
      height: 100%;
    }
}