.accessContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: var(--page-padding);
}

.loginBtn {
  padding: var(--navbar-button-padding) !important;
  height: 100% !important;
}

.topMargin {
  margin-top: var(--my-unit-3);
}

.logo {
  max-width: 200px;
}

.socialDivider {
  margin: var(--my-unit-5) 0;
  position: relative;
  display: block;
  height: 1px;
  width: 90%;
  max-width: 600px;
  border: 1px solid black;
  border-radius: 100px;
}

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen
  and (min-device-width: 768px) {
    .accessButton {
      font-size: var(--button-font-size-xl);
    }

    .loginBtn {
      font-size: var(--navbar-font-size-xl) !important;
    }
    /* .nextBtn {
      text-align: right !important;
    }
    .backBtn {
      text-align: start !important;
    } */
}